<template>
    <div class="upload_ph">
        <div class="tip_top">
            <img src="@/assets/warn_tip.png" width="3%" />
            <p>上传过程不能退出应用,手机/电脑与电视应保持在同一局域网内！</p>
        </div>
        <!-- 上传 -->
        <div class="ph_top"
            :style="{ background: bg ? '#4075EB' : 'linear-gradient(135deg, rgba(20, 78, 206, 0.96) 0%, #2960D4 100%)' }">
            <div class="length">
                <div class="upload_img" v-if="fileList.length == 0">
                    <img src="@/assets/upload_tip.png" v-show="fileList.length == 0" />
                    <!-- <p style="font-size:3.7vw" v-show="fileList.length == 0">{{ `点击区域选择${bg ? '图片' : '文件'}上传` }}</p> -->
                    <p class="types" v-show="fileList.length == 0 && !bg">
                        支持png、jpg、jpeg、mp4、mov、pdf、doc/docx、xlsx、apk、ppt文件格式</p>
                    <p style="font-size: 3.3vw;color: rgba(255,255,255,0.4);" v-show="fileList.length == 0 && !bg">
                        更多格式正在增加中……</p>
                    <p class="types" v-show="fileList.length == 0 && bg">
                        支持png、jpg、jpeg、webp图片格式</p>
                </div>
                <!-- 上传列表 -->
                <div class="fileList" v-if="fileList.length > 0">
                    <div v-for="(item, index) in fileList" :key="index" class="file_item">
                        <img src="@/assets/fail.png" class="type_img" v-if="item.status == 'failed'" />
                        <div v-if="item.status != 'failed'">
                            <img src="@/assets/apk_upload.png" class="type_img"
                                v-if="item.file.name.includes('.apk')" />
                            <img src="@/assets/doc_upload.png" class="type_img"
                                v-if="item.file.name.includes('.doc')" />
                            <img src="@/assets/img_upload.png" class="type_img"
                                v-if="item.file.type.includes('image')" />
                            <img src="@/assets/mp_upload.png" class="type_img" v-if="item.file.name.includes('.mp4')" />
                            <img src="@/assets/pdf_upload.png" class="type_img"
                                v-if="item.file.name.includes('.pdf')" />
                            <img src="@/assets/xlsx_upload.png" class="type_img"
                                v-if="item.file.name.includes('.xlsx')" />
                            <img src="@/assets/ppt_upload.png" class="type_img"
                                v-if="item.file.name.includes('.ppt') || item.file.name.includes('.pptx')" />
                            <img src="@/assets/txt_upload.png" class="type_img"
                                v-if="item.file.name.includes('.txt')" />
                        </div>
                        <div class="upload_right">
                            <div class="name">
                                <p class="name_top">{{ item.file.name }}</p>
                                <span style="font-size:3.3vw;">{{ conversion(item.file.size) }}</span>
                            </div>
                            <p v-if="item.status == 'failed'">
                                <img src="@/assets/error_tip.png" />
                                <span class="words">{{ item.type == 0 ? '上传失败，文件格式错误'
                                    : item.type == 1 ? '上传失败，文件已存在' :
                                        '上传失败，稍后再试'
                                    }}</span>
                            </p>
                            <p v-if="item.status != 'failed' && (item.uploadPercent == 100 || item.uploadPercent == 0)"
                                class="no_fail">
                                <img src="@/assets/suc_tip.png" v-if="item.uploadPercent == 100" />
                                <img src="@/assets/wait.png" v-if="item.uploadPercent == 0" style="margin-top:0.2vw;" />
                                <span class="words">{{ item.uploadPercent == 0 ? '待上传' : '上传成功' }}</span>
                            </p>
                            <van-progress :percentage="item.uploadPercent" color="#3FBCFF" track-color="rgba(0,0,0,0.1)"
                                :show-pivot="false" style="margin-top: 3px;"
                                v-if="item.status != 'failed' && item.uploadPercent != 100 && item.uploadPercent > 0" />
                        </div>
                    </div>
                </div>
                <div style="width:100%;height:16%;display: flex;align-items: center;justify-content: center;">
                    <van-uploader v-model="fileList" accept="" :preview-image="false" ref="upload"
                        :before-read="file => beforeRead(file, true)"
                        :after-read="(file, detail) => afterRead(file, detail, true)">
                    </van-uploader>
                    <p class="upload_btn" @click="uploadImg">{{ bg ? '上传图片' : '上传文件' }}</p>
                </div>
            </div>
        </div>

        <!-- 应用列表 -->
        <div class="app_list" v-show="moveFlag == 0 && !bg">
            <div class="type" v-if="menuList.length > 0">
                <div class="type_name" @click="getList(0)"
                    :style="{ color: type == 0 ? '#fff' : '#333', background: type == 0 ? '#4580FF' : 'none', paddingLeft: type == 0 ? '3%' : '0' }">
                    {{ menuList[0].name }}
                </div>
                <div class="type_name" @click="getList(1)" v-if="menuList.length > 1"
                    :style="{ color: type == 1 ? '#fff' : '#333', background: type == 1 ? '#4580FF' : 'none' }">
                    {{ menuList[1].name }}
                </div>
            </div>
            <div class="app_con" v-show="list.length > 0">
                <div class="title_out" v-if="classify == 4 && typeList.length > 0">
                    <div class="title">
                        <div class="title_item" v-for="(item, ind) in typeList" :key="ind"
                            :style="{ color: typeHot == ind ? '#333' : '#999', fontWeight: typeHot == ind ? '500' : '400' }">
                            <div style="position: relative;">
                                <p @click="typeChoose(item, ind)" style="cursor: pointer;">{{ item.name }}</p>
                                <div class="line_two" v-show="typeHot == ind"
                                    :style="{ background: typeHot == ind ? '#4089F8' : '#FAFAFA' }" />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="loading" style="text-align: center;height:45vw;line-height: 45vw;">
                    <van-loading size="24px">加载中...</van-loading>
                </div>
                <div class="apps" v-if="list.length > 0 && !loading">
                    <div class="items" v-for="(val, index) in list" :key="index" v-if="index < 4">
                        <img :src="val.icon" width="60vw" style="border-radius: 30%;" />
                        <p class="name">{{ val.name }}</p>
                        <div style="height:5vw">
                            <div class="state " v-if="val.state != 1" @click="updateClick(val, true)" :style="{
                                color: buttonText(val.state, 1), background: buttonText(val.state, 2),
                                border: val.state == 2 ? '1px solid #4284F5' : val.state == 3 ? '1px solid #22C19E' : 'none',
                            }">
                                {{ buttonText(val.state, 0) }}
                            </div>
                            <div v-if="val.state == 1" class="state stateDown">
                                <van-progress :percentage="val.describe" color="#2E6EFD"
                                    track-color="rgba(46,110,253,0.1)" :show-pivot="false" />
                                <p style="color:#fff">{{ `${val.describe}%` }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="more_app" @click="goMore" v-show="list.length > 4 && !loading">
                    <span>查看更多</span>
                    <img src="@/assets/arrowR.png" style="width:4.5vw" />
                </div>
            </div>
        </div>
        <!-- 移动按钮 -->
        <div>
            <div class="move" v-show="moveFlag != 0"
                :style="{ height: '11vw', width: moveFlag == 2 ? '11vw' : '30vw', left: left + 'px', top: top + 'px' }"
                ref="move" @touchstart.stop.prevent="(e) => { dragStart(e) }"
                @touchend.stop.prevent="(e) => { dragEnd(e) }" @touchmove.stop.prevent="(e) => { dragProgress(e) }">
                <img src="@/assets/move_tip.png" :style="{ marginRight: moveFlag == 1 ? '2vw' : '0' }" />
                <p v-if="moveFlag == 1">推荐应用</p>
            </div>
        </div>
        <!-- 更改了需求，该弹窗不会再出现，所以被弃用了 -->
        <van-popup v-model:show="show" position="bottom"
            :style="{ height: type == 0 ? '30vh' : '35vh', borderRadius: '4vw 4vw 0 0', }">
            <img src="@/assets/close_tip.png" style="position: absolute;
            top:2.3vw;right:2.8vw;width:6.5vw" @click="show = false" />
            <div class="app_list ">
                <div class="type" style="justify-content: flex-start;padding:3vw 2vw 0;" v-if="menuList.length > 0">
                    <div class="type_name" @click="getList(0)" style="margin-left: 2vw">
                        <p
                            :style="{ color: type == 0 ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)', fontSize: type == 0 ? '3.9vw' : '3.3vw' }">
                            {{
                                menuList[0].name }}</p>
                        <div class="line" :style="{ background: type == 0 ? '#4089F8' : '#fff' }" />
                    </div>
                    <div class="type_name" @click="getList(1)" v-if="menuList.length > 1">
                        <p
                            :style="{ color: type == 1 ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)', fontSize: type == 1 ? '3.9vw' : '3.3vw' }">
                            {{
                                menuList[1].name }}</p>
                        <div class="line" :style="{ background: type == 1 ? '#4089F8' : '#fff' }" />
                    </div>
                </div>
                <div class="app_con">
                    <div class="title_out" v-if="classify == 4 && typeList.length > 0">
                        <div class="title">
                            <p class="title_item" v-for="(item, ind) in typeList" :key="ind"
                                @click="typeChoose(item, ind)"
                                :style="{ marginLeft: ind == 0 ? '5vw' : '0', background: typeHot == ind ? '#E7EFFF' : '#EFF0F1', color: typeHot == ind ? '#447EF1' : 'rgba(0,0,0,0.6)' }">
                                {{ item.name }}</p>
                        </div>
                    </div>
                    <div v-if="loading" style="text-align: center;height:45vw;line-height: 45vw;">
                        <van-loading size="24px">加载中...</van-loading>
                    </div>

                    <div class="apps">
                        <div class="items" v-for="(val, index) in list" :key="index" v-if="index < 4">
                            <img :src="val.icon" width="57vw" />
                            <p class="name">{{ val.name }}</p>
                            <div style="height:4vw">
                                <div class="state" v-if="val.state != 1" @click="updateClick(val, true)" :style="{
                                    color: buttonText(val.state, 1), background: buttonText(val.state, 2),
                                    border: val.state == 2 ? '1px solid #4284F5' : val.state == 3 ? '1px solid #22C19E' : 'none',
                                }">
                                    {{ buttonText(val.state, 0) }}
                                </div>
                                <div v-if="val.state == 1" class="state stateDown">
                                    <van-progress :percentage="val.describe" color="#177DDC" track-color="#E1E8F9"
                                        :show-pivot="false" />
                                    <p style="color:#fff">{{ `${val.describe}%` }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="more_app" @click="goMore" v-show="list.length > 4 && !loading"
                        style="margin-top: 3.5vh;">
                        <span>查看更多</span>
                        <van-icon name="arrow" style="font-size:3.4vw;;padding:0px;margin:0px 0 0 0.8vw;" />
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import { Toast } from "vant";
import axios from "axios";
import { Popup } from 'vant';
import pushJs from '@/mixins/push.js'
import commonJs from '@/mixins/common.js'
export default {
    mixins: [pushJs, commonJs],
    data() {
        return {
            loading: false,
            show: false,
            uploadUrl: '',
            fileList: [],
            num: [],
            active: 0,
            typeHot: 0, // 热门类别分类
            type: 0, // 装机必备&热门类别,
            list: [],// 应用数据列表
            moveFlag: 0,// 移动按钮显示 0 显示应用列表  1 显示移动按钮(带字的) 2显示移动按钮(不带字)
            uploadPercent: '',//上传文件百分比
            appList: [],// 应用列表
            menuList: [],
            typeList: [], // 热门分类列表数据
            appUrl: '',// 获取地址
            classify: 0,
            moveSign: false,
            bg: false,
        }
    },
    created() {
        this.uploadUrl = this.$route.query?.url
        // this.uploadUrl = 'http://192.168.3.238:23577'
        this.bg = this.$route.query?.bg ? this.$route.query?.bg : false
        !this.bg ? this.getMenuList(true) : ''
    },

    methods: {
        //按钮字体、颜色、背景
        buttonText(state, sign) {
            switch (state) {
                case 0:
                    return sign == 0 ? "下载" : sign == 1 ? '#2E6EFD' : 'rgba(46,110,253,0.1)';
                case 2:
                    return sign == 0 ? "打开" : sign == 1 ? '#4285F5' : '#F5F5F5';
                case 3:
                    return sign == 0 ? "更新" : sign == 1 ? '#22C19E' : '#F5F5F5';
                case 4:
                    return sign == 0 ? "安装" : sign == 1 ? '#fff' : '#2E6EFD';
            }
        },
        uploadImg() {
            this.$refs.upload.chooseFile()
        }
    },
    mounted() { },
}
</script>
<style lang="less" scoped>
.upload_ph {
    width: 100vw;

    .tip_top {
        padding: 1.5% 1.3%;
        width: 100%;
        display: flex;
        align-items: center;
        background: #FFE7E7;
        font-size: 3.15vw;
        color: #666;

        img {
            width: 2.8%;
            margin: 0 1% 0 1.7%;
        }
    }

    .ph_top {
        width: 100%;
        height: 58vh;

        .van-uploader {
            height: 1%;
            width: 1%;
            text-align: center;
            display: none;
        }

        .length {
            width: 100%;
            height: 100%;
            padding-top: 1.5vh;

            .upload_img {
                color: #fff;
                text-align: center;
                border: 1px dashed rgba(255, 255, 255, 0.2);
                border-radius: 3%;
                width: 94%;
                height: 84%;
                margin: 0 auto;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                img {
                    margin-bottom: 1.5vh;
                    width: 18%;
                }

                .types {
                    font-size: 3.6vw;
                    color: rgba(255, 255, 255, 0.8);
                    width: 90%;
                    margin: 1.2vh 0;
                }
            }

            .upload_btn {
                font-size: 4.3vw;
                width: 45%;
                height: 65%;
                background: #FF8F1F;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
            }
        }

    }

    .fileList {
        width: 94%;
        margin: 0 auto;
        height: 84%;
        padding: 2.5% 1.5vh;
        overflow-y: auto;
        border: 1px dashed rgba(255, 255, 255, 0.2);
        border-radius: 3%;

        .file_item {
            margin-bottom: 1vh;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 2vw;
            padding: 1.9vw 3.5vw 1.9vw 2vw;
            display: flex;
            justify-content: space-between;
            color: #fff;
            font-size: 3.8vw;
            align-items: center;

            .type_img {
                width: 10vw;
                margin-right: 1vw;
            }

            .upload_right {
                width: 86.9%;

                .name {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0.5vw;

                    .name_top {
                        font-size: 3.3vw;
                        width: 73%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-bottom: 1%;
                    }
                }

                img {
                    width: 3.5vw;
                    margin-right: 1vw;

                }

                .words {
                    font-size: 3.2vw;
                }

                .no_fail {
                    display: flex;
                    align-items: center;
                }
            }
        }

        /deep/ .van-progress {
            margin-top: 0.8vh !important;
            height: 0.9vh;
        }


    }

    .app_list {
        width: 100%;
        padding: 0 1.5vw;

        .type {
            display: flex;
            align-items: center;
            padding: 2vh 0 0 2.5vw;

            .type_name {
                margin-right: 4vw;
                cursor: pointer;
                font-size: 4.2vw;
                padding: 1.5% 3%;
                border-radius: 8px;
            }
        }

        .app_con {
            padding: 0 2.8vw 1vh;

            .title_out {
                height: 7.3vw;
                border-bottom: 1px solid #EBEBEB;
                margin-top: 3vw;

                .title {
                    display: flex;
                    align-items: center;
                    padding: 0 0 30vw;
                    width: 100%;
                    overflow-x: auto;

                    .title_item {
                        color: rgba(0, 0, 0, 0.6);
                        font-size: 3.8vw;
                        border-radius: 2.5vw;
                        margin: 0 6vw 0 0;
                        white-space: nowrap;

                        .line_two {
                            position: absolute;
                            width: 100%;
                            height: 16%;
                            border-radius: 8px;
                            margin-top: 5px;
                            bottom: -35%;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }

            .apps {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 3.4vw;
                margin-top: 1.8vh;

                .items {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .name {
                        width: 20vw;
                        text-align: center;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin: 0.8vh 0;
                        color: rgba(0, 0, 0, 0.8);
                    }

                    .state {
                        height: 6.5vw;
                        line-height: 6.5vw;
                        width: 15vw;
                        text-align: center;
                        border-radius: 3vw;
                        font-size: 3.6vw;
                    }

                    .stateDown {
                        overflow: hidden;
                        position: relative;

                        .van-progress {
                            height: 100%;
                            border-radius: 4vw;
                        }

                        /deep/.van-progress__portion {
                            border-radius: 0 4px 0 4px;
                        }

                        p {
                            position: absolute;
                            top: 0;
                            left: 52%;
                            transform: translate(-50%);
                            color: #4383F4;
                        }
                    }
                }
            }

            .more_app {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 4.2vw;
                margin-top: 6vw;
                color: #333;
            }
        }
    }

    .move {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(180deg, #76A7FF 0%, #508DFF 79%, #327AFF 100%);
        box-shadow: 0px 2px 4px 0px #9EC0FF;
        border-radius: 10vh;
        font-size: 3.6vw;
        color: #fff;
        overflow: hidden;


        img {
            width: 4.2vw;
        }
    }

    .pop_tab {
        display: flex;
        font-size: 3.6vw;
        color: rgba(0, 0, 0, 0.5);
        padding: 4vw 0 0;
        align-items: center;
        width: 100%;
        margin: 0;

        .tab_item {
            margin: 0 3vw;
        }

        .more_app {
            padding: 2.5vh 0 0;
        }
    }
}
</style>