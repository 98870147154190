import { get } from "@/utils/request";
import axios from "axios";
import { Toast } from "vant";
export default {
  methods: {
    // 获取分类&装机必备等菜单栏
    getMenuList(flag) {
      let query = this.$route.query;
      this.typeHot = query.typeHot;
      this.type = query.type;
      let that = this;
      let params = {
        menu: flag ? true : false,
        size: 200,
        current: 1,
        type: 1,
        classify: flag ? "" : 4,
      };
      get(`/push/type/query`, params)
        .then((res) => {
          if (res?.code === 200) {
            flag
              ? (that.menuList = [...res.data.records])
              : (that.typeList = [...res.data.records]);
            // 如果是热门类别，要请求下边的分类列表
            if (flag) {
              that.getMenuList(false);
            } else {
              that.classify = that.menuList[that.type].classify;
              let id =
                that.classify == 4
                  ? that.typeList[that.typeHot].id
                  : that.menuList[that.type].id;
              that.getAppList(id);
            }
          }
        })
        .catch((err) => {
          Toast.clear();
        });
    },
    getAppList(val) {
      this.loading = true;
      let that = this;
      let params = {
        typeId: val,
        size: 200,
        current: 1,
      };
      get("/push/type/app/query", params)
        .then((res) => {
          if (res.code == 200) {
            that.list = res.data;
            that.list.forEach((item) => {
              that.$set(item, "state", 0);
            });
            that.$emit("getInstall");
          }
        })
        .catch((err) => {
          this.loading = false;
          Toast.clear();
        });
    },
    getList(ind) {
      if (ind != this.type) {
        this.type = ind;
        this.classify = this.menuList[this.type].classify;
        let id =
          this.classify == 4
            ? this.typeList[this.typeHot].id
            : this.menuList[0].id;
        this.getAppList(id);
      }
    },
    // 切换热门类型下所有分类
    typeChoose(item, ind) {
      if (this.typeHot != ind) {
        this.typeHot = ind;
        this.getAppList(item.id);
      }
    },
  },
};
