<template>
    <div class="upload_pc">
        <div :class="bg ? 'upload_tops' : 'upload_top'">
            <div class="header" :style="{ paddingTop: bg ? '100px' : '30px' }">
                <div class="header_title">
                    <img src="@/assets/icon.png" class="header_icon">
                    <span class="title" style="font-size:24px">远程推送</span>
                </div>
                <span class="tip">温馨提示：上传过程中请不要退出电视应用，手机或电脑应处于同一局域网内</span>
            </div>
            <div class="upload" :style="{ height: bg ? '500px' : '420px' }">
                <van-uploader v-model="fileList" accept="" multiple :preview-image="false"
                    :before-read="file => beforeRead(file, false)"
                    :after-read="(file, detail) => afterRead(file, detail, false)">
                    <div class="upload_con">
                        <div class="upload_img" v-if="fileList.length == 0">
                            <img src="@/assets/pc_upload.png" />
                            <p>{{ `点击区域选择${bg ? '图片' : '文件'}上传` }}</p>
                        </div>
                        <p class="types" v-if="fileList.length == 0 && !bg">
                            支持png、jpg、jpeg、mp4、mov、pdf、doc/docx、xlsx、apk、ppt、txt文件格式
                        </p>
                        <p class="more" v-if="fileList.length == 0 && !bg">更多格式正在增加中…</p>
                        <p class="types" v-if="fileList.length == 0 && bg">
                            支持png、jpg、jpeg、webp图片格式
                        </p>
                    </div>
                    <div class="upload_btn">
                        {{ bg ? '上传图片' : "上传文件" }}
                    </div>
                </van-uploader>
                <div class="warn_tip" v-if="chrome">
                    <div style="display: flex;align-items: center;">
                        <img src="@/assets/warn_tip.png" />
                        <div>
                            <span>谷歌浏览器如碰到传输失败的可尝试请前往</span>
                            <span
                                style="color:#2F6FFD;text-decoration: underline">chrome://flags/#block-insecure-private-network-requests</span>
                            <span>，</span>
                            <div>将插件 Block insecure private network requests 设置为 Disabled</div>
                        </div>
                    </div>
                    <van-icon name="cross" @click="chrome = false" />
                </div>
                <div class="fileList" v-if="fileList.length > 0" :style="{ maxHeight: bg ? '411px' : '331px' }">
                    <div v-for="(item, index) in fileList" :key="index" class="file_item"
                        :style="{ marginRight: Math.floor(index / 2) == index / 2 ? '10px' : '0px' }">
                        <img src="@/assets/fail.png" class="type_img" v-if="item.status == 'failed'" />
                        <div v-if="item.status != 'failed'">
                            <img src="@/assets/apk_upload.png" class="type_img"
                                v-if="item.file.name.includes('.apk')" />
                            <img src="@/assets/doc_upload.png" class="type_img"
                                v-if="item.file.name.includes('.doc')" />
                            <img src="@/assets/img_upload.png" class="type_img"
                                v-if="item.file.type.includes('image')" />
                            <img src="@/assets/mp_upload.png" class="type_img" v-if="item.file.name.includes('.mp4')" />
                            <img src="@/assets/pdf_upload.png" class="type_img"
                                v-if="item.file.name.includes('.pdf')" />
                            <img src="@/assets/xlsx_upload.png" class="type_img"
                                v-if="item.file.name.includes('.xlsx')" />
                            <img src="@/assets/ppt_upload.png" class="type_img"
                                v-if="item.file.name.includes('.ppt') || item.file.name.includes('.pptx')" />
                            <img src="@/assets/txt_upload.png" class="type_img"
                                v-if="item.file.name.includes('.txt')" />
                        </div>
                        <div class="upload_right">
                            <div class="name">
                                <p class="name_top">{{ item.file.name }}</p>
                                <span style="font-size:12px;color: rgba(0,0,0,0.4);">{{
                                    conversion(item.file.size) }}</span>
                            </div>
                            <p v-if="item.status == 'failed'">
                                <img src="@/assets/error_tip.png" width="16px" />
                                <span class="words" style="color:#999;">{{ item.type == 0 ?
                                    '上传失败，文件格式错误'
                                    : item.type == 1 ? '上传失败，文件已存在' :
                                        '上传失败，稍后再试'
                                    }}</span>
                            </p>
                            <p v-if="item.status != 'failed' && (item.uploadPercent == 100 || item.uploadPercent == 0)"
                                class="no_fail">
                                <img src="@/assets/suc_tip.png" v-if="item.uploadPercent == 100" />
                                <img src="@/assets/wait.png" v-if="item.uploadPercent == 0" style="margin-top:0.2vw;" />
                                <span class="words">{{ item.uploadPercent == 0 ? '待上传' : '上传成功' }}</span>
                            </p>
                            <div style="height:10px;margin-top:6px"
                                v-if="item.status != 'failed' && item.uploadPercent != 100 && item.uploadPercent > 0">
                                <van-progress :percentage="item.uploadPercent" color="#177DDC" track-color="#E1E8F9"
                                    :show-pivot="false"
                                    v-if="item.status != 'failed' && item.uploadPercent != 100 && item.uploadPercent > 0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="overflow: hidden;" v-if="!bg">
            <div class="appList">
                <div class="type_top" v-if="menuList.length > 0">
                    <div class="type">
                        <div class="type_name" @click="getList(0)"
                            :style="{ color: type == 0 ? '#fff' : '#333', background: type == 0 ? '#4580FF' : 'none', paddingLeft: type == 0 ? '15px' : '0px' }">
                            {{ menuList[0].name }}
                        </div>
                        <div class="type_name" @click="getList(1)" v-if="menuList.length > 1"
                            :style="{ color: type == 1 ? '#fff' : '#333', background: type == 1 ? '#4580FF' : 'none' }">
                            {{ menuList[1].name }}
                        </div>
                    </div>
                    <div class="more" @click="goMore" v-if="list.length > 5">
                        <span>查看更多</span>
                        <img src="@/assets/arrowR.png" width="16px" />
                    </div>
                </div>
                <div class="type_hot" v-if="typeList.length > 0 && menuList[type].classify == 4">
                    <div class="title_item" v-for="(item, ind) in typeList" :key="ind"
                        :style="{ color: typeHot == ind ? '#333' : '#999', fontWeight: typeHot == ind ? '500' : '400' }">
                        <div style="position: relative;">
                            <div @click="typeChoose(item, ind)" style="cursor: pointer;">{{ item.name }}</div>
                            <div class="line_two" v-show="typeHot == ind"
                                :style="{ background: typeHot == ind ? '#4089F8' : '#FAFAFA' }" />
                        </div>
                    </div>

                </div>
                <div class="list_item" v-show="!loading">
                    <div class="item" v-for="(item, ind) in list" :key="ind" v-if="ind < 5">
                        <img :src="item.icon" />
                        <div class="appName">{{ item.name }}</div>
                        <div class="item_btn" v-if="item.state != 1" @click="updateClick(item, true)" :style="{
                            color: item.state == 0 ? '#2E6EFD' : item.state == 2 ? '#2E6EFD' : '#fff', background: item.state == 0 ? 'rgba(46,110,253,0.1)' : item.state == 2 ? 'none' : item.state ==
                                3 ? '#22C19E' : item.state == 4 ? '#2E6EFD' : '', border: item.state == 2 ? '1px solid #2E6EFD' : 'none'
                        }">
                            {{ item.state == 0 ? '下载' : item.state == 2 ? '打开' : item.state ==
                                3 ? '更新' : item.state == 4 ? '安装' : ''
                            }}
                        </div>
                        <div class="item_prog" v-if="item.state == 1">
                            <van-progress :percentage="item.describe" color="#2E6EFD" track-color="rgba(46,110,253,0.1)"
                                :show-pivot="false" style="margin:0px;height:24px;border-radius: 8px;" />
                            <p class="percentages">
                                {{ `${item.describe}%` }}</p>
                        </div>
                    </div>
                </div>
                <div v-if="loading" style="text-align: center;margin-top: 50px;">
                    <van-loading size="24px">加载中...</van-loading>
                </div>
            </div>
        </div>
        <!-- flag 字段是否从遥控首页进来，因为遥控首页进来需要返回按钮，其他扫码进来不需要 -->
        <img ref="imgBack" @click="onClickLeft" v-if="$route.query.flag" class="backImg" src="@/assets/goback.png" />
    </div>
</template>
<script>
import pushJs from '@/mixins/push.js'
import axios from "axios";
import common from '@/mixins/common.js'
export default {
    mixins: [pushJs, common],
    data() {
        return {
            loading: false,
            uploadUrl: '',
            fileList: [
                // { file: { name: '33fff.apk', size: 3000, type: 'apk' }, status: '', type: 2, uploadPercent: 29 }, { file: { name: '33fff.apk', size: 3000, type: 'apk' }, status: 'failed', type: 2, uploadPercent: 29 },
            ],
            num: [],
            active: 0,
            typeHot: 0, // 热门类别分类
            type: 0, // 装机必备&热门类别,
            list: [
                // {
                //     icon: "http://cdnpush.ikukan.cn/app_push/image/icon/20221028/379712tv_video_10.8.0.1014_android_16280.png",
                //     name: "云视听极光",
                //     pkg: "com.ktcp.video",
                //     state: 1,
                //     describe: 19,
                // }
            ],// 应用数据列表
            moveFlag: 0,// 移动按钮显示 0 显示应用列表  1 显示移动按钮(带字的) 2显示移动按钮(不带字)
            uploadPercent: '',//上传文件百分比
            appList: [],// 应用列表
            menuList: [{
                name: '装机必备',
            }, {
                name: '热门类别',
            }], // 菜单栏列表
            typeList: [
                // { name: '影音' }, { name: '防腐剂' }
            ], // 热门分类列表数据
            chrome: false, // 是否是谷歌浏览器
            bg: false,// 是否是客户端壁纸页面扫码进来，毕业页面不需要连接socket，也没有应用下载之类的
        }
    },
    created() {
        const userAgent = navigator.userAgent;
        if (userAgent.indexOf("Chrome") !== -1 && userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Edg") === -1) {
            this.chrome = true
        }
        // this.uploadUrl = 'http://192.168.0.22:23577'
        this.uploadUrl = this.$route.query?.url
        this.bg = this.$route.query?.bg ? this.$route.query?.bg : false
        !this.bg ? this.getMenuList(true) : ''
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        },
    }

}
</script>
<style lang="less" scoped>
.upload_pc {
    background-color: #FAFAFA;
    min-height: 100vh;
    min-width: 1200px;
    font-size: 14px;

    .upload_tops {
        width: 100%;
        background-color: #ccc;
        min-width: 1200px;
        background: url('../../assets/bg_all.png') no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;
    }

    .upload_top {
        width: 100%;
        background-color: #ccc;
        min-width: 1200px;
        background: url('../../assets/bg_upload.png') no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 600px;
    }

    .header {
        text-align: center;
        color: #fff;
        padding: 0 0 30px;

        .header_title {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            margin-bottom: 10px;

            .header_icon {
                width: 40px;
                margin-right: 4px;
            }
        }

        .tip {
            font-size: 16px;
        }
    }

    .upload {
        width: 900px;
        background-color: #fff;
        border-radius: 10px;
        border: none !important;
        font-size: 12px;
        color: #4285F6;
        position: relative;
        overflow: hidden;

        .van-uploader {
            height: 100%;
            width: 100% !important;
        }

        /deep/.van-uploader__wrapper {
            height: 100% !important;
            width: 100% !important;
        }

        /deep/ .van-uploader__input-wrapper {
            height: 100% !important;
            width: 100% !important;
        }

        /deep/ .van-uploader__upload-text {
            color: #4285F6;
            font-weight: 400;
        }

        .upload_con {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 14px;
            margin-top: -40px;

            .upload_img {
                text-align: center;
                font-size: 14px;
                color: #4287F7;

                img {
                    width: 88px;
                    height: 88px;
                }
            }
        }

        .upload_btn {
            width: 160px;
            height: 48px;
            background: #FF8F1F;
            border-radius: 8px 8px 8px 8px;
            color: #fff;
            text-align: center;
            line-height: 48px;
            font-size: 20px;
            margin: -27px auto;
        }


        .fileType {
            color: #4285F6;
        }

        .more {
            color: #999;
            font-size: 12px;
        }

        .type_item {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 0 10px;

            .type_img {
                width: 40px;
                margin-bottom: 5px;
            }
        }

        .warn_tip {
            position: absolute;
            top: 0px;
            left: 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #FFE7E7;
            height: 56px;
            padding: 0 18px 0 20px;
            font-size: 14px;
            color: #666;
            width: 900px;
            z-index: 9999;

            img {
                width: 15px;
                height: 17px;
                margin: 0 14px 0 0;
            }
        }
    }

    .fileList {
        width: 900px;
        position: absolute;
        top: 0px;
        left: 0px;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0 10px 20px;

        .file_item {
            width: 425px;
            background-color: #fff;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1039);
            border-radius: 10px 10px 10px 10px;
            display: flex;
            justify-content: space-between;
            color: rgba(0, 0, 0, 0.8);
            align-items: center;
            margin: 10px 0 0;
            padding: 12px;
            height: 64px;

            .type_img {
                width: 40px;
                height: 40px;
                margin-right: 8px;
            }

            .upload_right {
                width: calc(100% - 40px);

                .name {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .name_top {
                        max-width: calc(100% - 70px);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #000;
                        font-size: 14px;
                        line-height: 20px;
                        margin-bottom: 3px;
                    }

                }

                img {
                    width: 12px;
                    margin-right: 5px;
                }

                .words {
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.5);
                }

                .no_fail {
                    display: flex;
                    align-items: center;
                }
            }
        }

        /deep/ .van-progress {
            height: 7px;
            background-color: #F0F0F0 !important;
        }

        /deep/ .van-progress__portion {
            background: #4189F9 !important;
        }
    }

    .appList {
        width: 1200px;
        margin: 20px auto;
        height: 266px;
        overflow: hidden;

        .type {
            display: flex;
            align-items: center;

            .type_name {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                margin-right: 20px;
                cursor: pointer;
                font-size: 18px;
                padding: 3px 15px;
                line-height: 27px;
                border-radius: 8px;
            }
        }

        .type_hot {
            display: flex;
            align-items: center;
            width: 1200px;
            border-bottom: 1px solid #EBEBEB;
            margin: 20px 0 0;

            .title_item {
                display: flex;
                align-items: center;
                margin-right: 40px;
                white-space: nowrap;
                padding-bottom: 5px;
                font-size: 16px;

                .line_two {
                    position: absolute;
                    bottom: -7px;
                    left: 50%;
                    width: 100%;
                    transform: translateX(-50%);
                    height: 4px;
                    background: #4089F8;
                    border-radius: 2px 2px 2px 2px;
                    margin-top: 5px;
                }
            }
        }

        .list_item {
            display: flex;
            align-items: center;
            overflow-x: hidden;
            width: 1200px;
            margin-top: 20px;

            .item {
                background: rgba(255, 255, 255, 0.8);
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
                border-radius: 8px 8px 8px 8px;
                margin-right: 15px;
                padding: 20px 28px;
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    width: 64px;
                }

                .appName {
                    width: 172px;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.8);
                    line-height: 22px;
                    margin: 10px 0 5px;
                    font-weight: 500;
                }

                .item_btn {
                    width: 172px;
                    height: 24px;
                    background: #2E6EFD;
                    text-align: center;
                    border-radius: 8px;
                    line-height: 24px;
                    cursor: pointer;
                }

                .item_prog {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    font-size: 12px;
                    position: relative;

                    .percentages {
                        position: absolute;
                        top: 5px;
                        color: #fff;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    /deep/ .van-progress__pivot {
                        background: none !important;
                        margin-left: -10px;
                    }

                    /deep/ .van-progress {
                        width: 172px;
                        margin-right: 9px;
                    }
                }
            }
        }
    }

    .type_top {
        width: 1200px;
        display: flex;
        justify-content: space-between;

        .more {
            display: flex;
            align-items: center;
        }
    }

    .types {
        font-size: 14px;
        color: #999;
        margin: 10px;
        line-height: 20px;
    }

    .backImg {
        position: fixed;
        bottom: 50px;
        right: 30px;
        width: 60px;
    }
}
</style>