<template>
    <div class="list_pc">
        <div class="top">
            <div class="title">
                <img src="@/assets/icon.png" width="24px" style="margin-right: 4px;" />
                <p>远程推送</p>
            </div>
        </div>
        <div class="content">
            <p style="font-size:24px;font-weight: bold;margin-top: 30px" v-if="menuList.length > 0">{{
                menuList[type].name }}</p>
            <div class="type_hot" v-if="typeList.length > 0 && menuList[type].classify == 4">
                <div class="title_item" v-for="(item, ind) in typeList" :key="ind"
                    :style="{ color: typeHot == ind ? '#333' : '#999', fontWeight: typeHot == ind ? '500' : '400' }">
                    <div style="position: relative;">
                        <p @click="typeChoose(item, ind)" style="cursor: pointer;">{{ item.name }}</p>
                        <div class="line_two" v-show="typeHot == ind"
                            :style="{ background: typeHot == ind ? '#4089F8' : '#FAFAFA' }" />
                    </div>
                </div>
            </div>
            <div class="appList" v-if="list.length > 0 && !loading"
                :style="{ maxHeight: menuList[type].classify == 4 ? 'calc(100vh - 195px)' : 'calc(100vh - 145px)' }">
                <div class="item" v-for="(item, ind) in list" :key="ind">
                    <img :src="item.icon" />
                    <div class="appName">{{ item.name }}</div>
                    <div class="item_btn" v-if="item.state != 1" @click="updateClick(item, true)" :style="{
                        color: item.state == 0 ? '#2E6EFD' : item.state == 2 ? '#2E6EFD' : '#fff', background: item.state == 0 ? 'rgba(46,110,253,0.1)' : item.state == 2 ? 'none' : item.state ==
                            3 ? '#22C19E' : item.state == 4 ? '#2E6EFD' : '', border: item.state == 2 ? '1px solid #2E6EFD' : 'none'
                    }">
                        {{ item.state == 0 ? '下载' : item.state == 2 ? '打开' : item.state ==
                            3 ? '更新' : item.state == 4 ? '安装' : ''
                        }}
                    </div>
                    <div class="item_prog" v-if="item.state == 1">
                        <van-progress :percentage="item.describe" color="#2E6EFD" track-color="rgba(46,110,253,0.1)"
                            :show-pivot="false" style="margin:0px;height:24px;border-radius: 8px;" />
                        <p class="percentages">
                            {{ `${item.describe}%` }}</p>
                    </div>
                </div>
            </div>
            <div v-if="loading" style="text-align: center;margin-top: 200px;">
                <van-loading size="24px">加载中...</van-loading>
            </div>
        </div>
        <img ref="imgBack" @click="onClickLeft" class="backImg" src="@/assets/goback.png" />
    </div>
</template>
<script>
import commonJs from '@/mixins/common.js'
import recommendJs from '@/mixins/recommend.js'
export default {
    mixins: [commonJs, recommendJs],
    data() {
        return {
            loading: false,
            type: 0,
            menuList: [], // 热门分类列表数据
            typeList: [],
            typeHot: 0,
            list: [
                // {
                //     icon: "http://cdnpush.ikukan.cn/app_push/image/icon/20221028/379712tv_video_10.8.0.1014_android_16280.png",
                //     name: "云视听极光",
                //     pkg: "com.ktcp.video",
                //     state: 1,
                //     describe: 19,
                // }
            ],// 应用数据列表
            classify: 0
        }
    },
    created() {
        this.typeList = []
        this.getMenuList(true)
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        },
    }
}
</script>
<style lang="less" scoped>
.list_pc {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 1200px;
    background: #F4F4F4;
    height: 100vh;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);

    .top {
        width: 100%;
        height: 50px;
        background: linear-gradient(135deg, rgba(20, 78, 206, 0.96) 0%, #2960D4 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;

        .title {
            width: 1200px;
            color: #fff;
            display: flex;
            align-items: center;

        }
    }

    .content {
        width: 1200px;

        .type_hot {
            display: flex;
            align-items: center;
            width: 1200px;
            border-bottom: 1px solid #EBEBEB;
            margin: 20px 0 0;

            .title_item {
                display: flex;
                align-items: center;
                margin-right: 40px;
                white-space: nowrap;
                padding-bottom: 5px;
                font-size: 16px;

                .line_two {
                    position: absolute;
                    width: 100%;
                    height: 4px;
                    border-radius: 2px 2px 2px 2px;
                    margin-top: 5px;
                    bottom: -7px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        .appList {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 1220px;
            margin-top: 20px;
            overflow-y: auto;

            .item {
                background: rgba(255, 255, 255, 0.8);
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
                border-radius: 8px 8px 8px 8px;
                margin: 0 15px 15px 0;
                padding: 20px 28px;
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    width: 64px;
                }

                .appName {
                    width: 172px;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.8);
                    line-height: 22px;
                    margin: 10px 0 5px;
                    font-weight: 500;
                }

                .item_btn {
                    width: 172px;
                    height: 24px;
                    background: #2E6EFD;
                    text-align: center;
                    border-radius: 8px;
                    line-height: 24px;
                    cursor: pointer;
                }

                .item_prog {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    font-size: 12px;
                    position: relative;

                    .percentages {
                        position: absolute;
                        top: 5px;
                        color: #fff;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    /deep/ .van-progress__pivot {
                        background: none !important;
                        margin-left: -10px;
                    }

                    /deep/ .van-progress {
                        width: 172px;
                        margin-right: 9px;
                    }
                }
            }
        }
    }

    .backImg {
        position: fixed;
        bottom: 100px;
        right: 80px;
        width: 60px;
    }
}
</style>